import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import MainImage from "../assets/strange-lot-logo-neoblack7.GIF";

const Landing = ({ setSelectedPage }) => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <div
      id="home"
      className={`flex bg-slow justify-center items-center bg-no-repeat relative mx-auto ${
        isAboveMediumScreens ? "h-[350px]" : "h-[200px]" // Adjust height for different screen sizes
      }`}
      style={{
        backgroundImage: `url(${MainImage})`,
        backgroundSize: "contain", // Keep the logo contained within the div
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%", // Ensure it spans the full width of the container
      }}
    >
      {/* MAIN div */}
      {/* Additional content can be added here if necessary */}
    </div>
  );
};

export default Landing;
